<template>
  <div>

    <form @submit.prevent="enviar_observacion">
      <div class="row mr-2">
        <div class="col-sm-12">
          <div class="input-group mb-3">
            <input v-model="observacion" type="text" class="form-control" placeholder="Observación..." aria-label="observacion" aria-describedby="button-addon2">
            <div class="input-group-append">
              <button class="btn btn-outline-primary">Agregar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    
    <div class="observaciones" id="observaciones">
      <template v-if="solicitud.observaciones.length > 0">
      <div v-for="observacion in observaciones_paginados" :key="observacion.id" class="observacion">
        <div class="descripcion" v-html="observacion.observacion"></div>
        <div class="usuario">{{ observacion.index+1 }} - {{ observacion.nombre }} - {{ $moment(observacion.created_at).format('YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
      </template>
      <div v-else class="observacion">
        <div class="descripcion">No hay observaciones</div>
        <div class="usuario">N/A - N/A - N/A</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_anterior">Anterior</a></li>
            <template v-for="num_pagina in paginas_mostrar">
            <li :class="'page-item'+(num_pagina == pagina_actual ? ' pagina_actual' : '')"><a class="page-link" href="#" @click.prevent="pagina_actual = num_pagina">{{ num_pagina }}</a></li>
            </template>
            <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_siguiente">Siguiente</a></li>
          </ul>
        </nav>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            observaciones: []
          }
        }
      }
    }
    ,data() {
      return {
        observacion: null
        ,pagina_actual: 1
        ,por_pagina: 10
        ,paginas_a_mostrar: 3
      }
    }
    ,methods: {
      async enviar_observacion() {
        try {
          let res = (await apiSolicitudes.agregar_observacion(this.solicitud.id, {observacion: this.observacion})).data;

          this.observacion = null;

          this.$log.info('res', res)
          this.$emit('update');
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,pagina_anterior() {
        if (this.pagina_actual == 1)
          return;

        this.pagina_actual -= 1;
      }
      ,pagina_siguiente() {
        if(this.pagina_actual == this.total_paginas)
          return;

        this.pagina_actual += 1;
      }
    }
    ,computed: {
      total_paginas() {
        return Math.ceil(this.solicitud.observaciones.length / this.por_pagina);
      }
      ,observaciones_paginados() {
        let observaciones = [];

        let inicio = (this.pagina_actual == 1 ? 0 : ((this.pagina_actual-1) * this.por_pagina));
        let final = (this.pagina_actual < this.total_paginas ? inicio+this.por_pagina : this.solicitud.observaciones.length)-1;

        this.$log.info('inicio: '+inicio+' - final: '+final);

        for(let i=inicio; i<=final; i++) {
          this.solicitud.observaciones[i].index = i;
          observaciones.push(this.solicitud.observaciones[i]);
        }

        return observaciones;
      }
      ,paginas_mostrar() {
        let inicio = this.pagina_actual  == 1 ? 1 : (this.pagina_actual > this.paginas_a_mostrar ? (this.pagina_actual-this.paginas_a_mostrar) : 1);
        let final = this.pagina_actual == this.total_paginas ? this.total_paginas : (this.pagina_actual < (this.total_paginas - this.paginas_a_mostrar) ? (this.pagina_actual + this.paginas_a_mostrar) : this.total_paginas);

        let paginas = [];

        for(let i=inicio; i<=final; i++) {
          paginas.push(i);
        }

        return paginas;
      }
    }
    ,watch: {
      pagina_actual() {
        $('html, body').animate({scrollTop: 0}, 'slow');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .observaciones {
    padding-right: 20px;
    .observacion {
      border:solid 1px #CCCCCC;
      border-radius: 3px;
      margin-bottom: 20px;

      .descripcion {
        padding: 5px 10px;
      }

      .usuario {
        background-color: #E6E6E6;
        padding: 5px 10px;
        font-size: 0.8em;
        border-top: solid 1px #BFBFBF;
      }
    }
  }

  .pagina_actual {
    a {
      background-color: #C4E5C5;
    }
  }
</style>